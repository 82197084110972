import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Products.css";
import {FormattedMessage, useIntl} from 'react-intl';
import { useData, useSelectedIndex } from "../../context/DataContext";
import onAirRed from '../../assets/Home/RedVersion-Online.svg';
import onAirGreen from "../../assets/Home/GreenVersion-Offline.svg";

const OnAirComponent = ({isAvailable}) => {
  return (
    <div className="onAirBox">
      {isAvailable === 'true' ? (
          <img src={onAirGreen} alt="Is available" />
        ) : (
          <img src={onAirRed} alt="Is not available" />
      )}
    </div>
  );
};


const Resume = (props) => {
  /* STATES */
  const { selectedIndex } = useSelectedIndex();
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});
  const { updateData } = useData();
  const intl = useIntl();

  useEffect(() => {
    setSelectedBulletIndex(selectedIndex);
    handleCarousal(selectedIndex);
  }, [selectedIndex]);

  const handleClick = (sectionName, messageId) => {

    const profileName = intl.formatMessage({ id: messageId, defaultMessage: '' });
    
    if(sectionName === 'profiles'){
      const concatenatedStrings = 
        intl.formatMessage({id: 'product.profiles.form.profile', defaultMessage: '' }) + 
        ` ${profileName}\n` + 
        intl.formatMessage({id: 'product.profiles.form.company', defaultMessage: '' }) + '\n' + 
        intl.formatMessage({id: 'product.profiles.form.need', defaultMessage: '' }) + '\n' + 
        intl.formatMessage({id: 'product.profiles.form.startDate', defaultMessage: '' }) + '\n' + 
        intl.formatMessage({id: 'product.profiles.form.description', defaultMessage: '' }); 

      updateData(concatenatedStrings);
    }
    if(sectionName === 'careers'){
      const concatenatedStrings = 
        intl.formatMessage({id: 'product.careers.form.role', defaultMessage: '' }) + 
        ` ${profileName}\n` + 
        intl.formatMessage({id: 'product.careers.form.contract', defaultMessage: '' }) + '\n' + 
        intl.formatMessage({id: 'product.careers.form.experience', defaultMessage: '' }) + '\n' + 
        intl.formatMessage({id: 'product.careers.form.aws', defaultMessage: '' }) + '\n' + 
        intl.formatMessage({id: 'product.careers.form.programmingSkill', defaultMessage: '' }) + '\n' +
        intl.formatMessage({id: 'product.careers.form.mediaSkill', defaultMessage: '' }) + '\n' +
        intl.formatMessage({id: 'product.careers.form.talkAboutYou', defaultMessage: '' }); 
      
      updateData(concatenatedStrings);
    }
    ScrollService.scrollHandler.scrollToHireMe()
  };

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? <FormattedMessage id={props.heading} /> : ""}</span>
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ?  <FormattedMessage id={props.subHeading} /> : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? <FormattedMessage id={props.description} /> : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "product.services", logoSrc: "services.svg" },
    { label: "product.products", logoSrc: "solution.svg" },
    { label: "product.profiles", logoSrc: "profiles.svg" },
    { label: "product.careers", logoSrc: "careers.svg" }
  ];

  const resumeDetails = [
    /* SERVICES */
    <div className="resume-screen-container" key="services">
      <div className="scrollable-div">

        <div className="experience-container">
          <ResumeHeading
            heading={"product.title.services"}
            subHeading={"product.subTitle.services"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
              <FormattedMessage id="product.description1.services" />
               <br /><br />

              <span className="resume-description-text">
                <li><FormattedMessage id="product.description1.sub1.services" /></li>
                <li><FormattedMessage id="product.description1.sub2.services" /></li>
                <li><FormattedMessage id="product.description1.sub3.services" /></li>
                <li><FormattedMessage id="product.description1.sub4.services" /></li>
                <li><FormattedMessage id="product.description1.sub5.services" /></li>
                <li><FormattedMessage id="product.description1.sub6.services" /></li>
                <br />
              </span>
            </span>


          </div>
        </div>

      </div>
    </div>,

    /* PRODUCT */
    <div className="resume-screen-container" key="solution">
      <div className="scrollable-div">
        <div className="experience-container">
          <ResumeHeading
            heading={"product.title.products.comingsoon"}
            subHeading={"product.subTitle.products.comingsoom"}
          />
        </div>
      </div>
    </div>,

    /* PROFILES */
    <div className="resume-screen-container" key="profiles">
      <div className="scrollable-div">
        <div className="experience-container">
          <ResumeHeading
            heading={"product.title.products.comingsoon"}
            subHeading={"product.subTitle.products.comingsoom"}
          />
        </div>
      </div>
    </div>,

    /* CAREERS */
    <div className="resume-screen-container" key="careers">
      <div className="scrollable-div">
        <div className="experience-container">
          <ResumeHeading
            heading={"product.title.products.comingsoon"}
            subHeading={"product.subTitle.products.comingsoom"}
          />
        </div>
      </div>
    </div>,

  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
          
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
          alt="B"
        />
        <span className="bullet-label"><FormattedMessage id={bullet.label} /></span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"product.title"} subHeading={"product.subTitle"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
