import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import load1 from "../../../src/images/load2.gif";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Footer from "../../PortfolioContainer/footer/Footer";
import "./ContactMe.css";
import {FormattedMessage} from 'react-intl';
import ReCAPTCHA from "react-google-recaptcha";
import { useData } from "../../context/DataContext";

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState(false);
  const [verify, setIsVerified] = useState(false);
  const { sharedData } = useData();

  useEffect(() => {
    if (sharedData !== null) {
      setMessage(sharedData);
    }
  }, [sharedData, setMessage]);


  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleOnChange = (e) => {
    setIsVerified(true);
  }

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      let data = {
        name,
        email,
        message,
      };
      if(verify === true){
        setBool(true);
      }
      

      if (name.length === 0 || email.length === 0 || message.length === 0) {
        setBanner("Fill all field");
        toast.error("Fill all field");
        setBool(false);
        return
      }
      

      const res = await axios.post(process.env.REACT_APP_API_URL, data);
      
      if (res.status === 200) {
        setBanner(res.data);
        toast.success(res.data);
        setBool(false);
        setIsVerified(false);
        setName("");
        setEmail("");
        setMessage("");
      }
    } catch (error) {
      setBanner("An error occurred retry later");
      toast.error("An error occurred retry later");
      setBool(false);
      setIsVerified(false);
    }
  };

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <ScreenHeading subHeading={"contact.subTitle"} title={"contact.title"} />
      <div className="central-form">
        <div className="col">
          <h2 className="title">
          <FormattedMessage id="contact.getInTouch" />
          </h2>{" "}
          <a href="https://www.facebook.com/HeadEndConsulting" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-square-facebook"></i>
          </a>
          <a href="https://twitter.com/HeadEndConsult" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-square-twitter"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCOdM-YN9bSyanizNGYSX5iQ" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-square-youtube"></i>
          </a>
          <a href="https://fr.linkedin.com/company/headendconsulting" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-linkedin"></i>
          </a>
          <a href="https://github.com/ethevee?tab=repositories" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-square-github"></i>
          </a>
          <a href="https://iq.aws/e/headendconsulting" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-aws"></i>
          </a>
        </div> 
        <div className="back-form">
        
          <div className="img-back">
            <h4><FormattedMessage id="contact.meetMe" /></h4>
            <iframe
              src="https://calendar.google.com/calendar/u/0/embed?color=%23fbe983&src=c_k1vll1ga4a7qs3cgvdpm1qkmfg@group.calendar.google.com&pli=1"
              title="Next event"
              width="auto"
              height="400"
              loading="lazy"
            />
          </div>
          <form onSubmit={submitForm}>
            <p>{banner}</p>
            <label htmlFor="name"><FormattedMessage id="contact.name" /></label>
            <input type="text" onChange={handleName} value={name} />

            <label htmlFor="email"><FormattedMessage id="contact.email" /></label>
            <input type="email" onChange={handleEmail} value={email} />

            <label htmlFor="message"><FormattedMessage id="contact.message" /></label>
            <textarea type="text" className="message-section" onChange={handleMessage} value={message} />

            <div className="send-btn">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              onChange={handleOnChange}
            />
              <button type="submit" disabled={!bool && !verify}>
                <FormattedMessage id="button.send" />
                <i className="fa fa-paper-plane" />
                {bool ? (
                  <b className="load">
                    <img src={load1} loading="lazy" alt="" />
                  </b>
                ) : (
                  ""
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
