import Home from "../PortfolioContainer/Home/Home";
import AboutMe from "../PortfolioContainer/AboutMe/AboutMe";
import Resume from "../PortfolioContainer/Resume/Resume";
import Projects from "../PortfolioContainer/Projects/Projects";
import ContactMe from "../PortfolioContainer/ContactMe/ContactMe";
import News from "../PortfolioContainer/News/News";
import Products from "../PortfolioContainer/Products/Products";
import Accreditations from "../PortfolioContainer/Accreditations/Accreditations";

export const TOTAL_SCREENS = [
  {
    screen_name: "",
    component: Home,
  },
  {
    screen_name: "Products",
    component: Products,
    submenu: [
      {
        name: 'Services',
      },
      {
        name: 'Products',
      },
      {
        name: 'Profiles',
      },
      {
        name: 'Careers',
      },
      // Add other submenu entries
    ],
  },
  {
    screen_name: "Accreditations",
    component: Accreditations,
  },
  {
    screen_name: "About",
    component: AboutMe,
  },
  {
    screen_name: "News",
    component: News,
  },
  {
    screen_name: "Projects",
    component: Projects,
  },
  {
    screen_name: "Resume",
    component: Resume,
    submenu: [
      {
        name: 'Education',
      },
      {
        name: 'Certification',
      },
      {
        name: 'Experience',
      },
      {
        name: 'Interest',
      },
      // Add other submenu entries
    ],
  },
  {
    screen_name: "Contact",
    component: ContactMe,
  },
];

export const GET_SCREEN_INDEX = (screen_name) => {
  if (!screen_name) return -1;
  
  for (let i = 0; i < TOTAL_SCREENS.length; i++) {
    if (TOTAL_SCREENS[i].screen_name === screen_name) return i;
  }

  return -1;
};
