import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";
import {FormattedMessage} from 'react-intl';
import { useSelectedIndex } from "../../context/DataContext";

const Resume = (props) => {
  /* STATES */
  const { selectedIndex } = useSelectedIndex();
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  useEffect(() => {
    setSelectedBulletIndex(selectedIndex);
    handleCarousal(selectedIndex);
  }, [selectedIndex]);

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? <FormattedMessage id={props.heading} /> : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? <FormattedMessage id={props.subHeading} /> : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? <FormattedMessage id={props.description} /> : ""}</span>
        </div>
      </div>
    );
  };

  const CertificationHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? <FormattedMessage id={props.heading} /> : ""}</span>
          {props.fromDate && props.year ? (
            <div className="heading-date">
              <FormattedMessage id={props.fromDate} /><FormattedMessage id={props.year} />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ?<FormattedMessage id={props.subHeading} /> : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ?<FormattedMessage id={props.description} /> : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "resume.section.education", logoSrc: "education.svg" },
    { label: "resume.section.certification", logoSrc: "certificate.svg" },
    { label: "resume.section.workHistory", logoSrc: "work-history.svg" },
    { label: "resume.section.interest", logoSrc: "interests.svg" },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"resume.education.title.esaip"}
        subHeading={"resume.education.subTitle.esaip"}
        fromDate={"2004"}
        toDate={"2008"}
      />

      <ResumeHeading
        heading={"resume.education.title.immaculeConception"}
        subHeading={"resume.education.subTitle.immaculeConception"}
        fromDate={"2003"}
        toDate={"2005"}
      />
      <ResumeHeading
        heading={"resume.education.title.jeanneArc"}
        subHeading={"resume.education.subTitle.jeanneArc"}
        fromDate={"2001"}
        toDate={"2003"}
      />
    </div>,

    <div className="resume-screen-container" key="certificate">
    <CertificationHeading
      heading={"resume.certification.title.solutionArchitect"}
      subHeading={"resume.certification.subTitle.solutionArchitect"}
      year={"resume.certification.fromYear.solutionArchitect"}
      fromDate={"month.jan"}
      toDate={"presentDay"}
    />

    <CertificationHeading
      heading={"resume.certification.title.itil"}
      subHeading={"resume.certification.subTitle.itil"}
      year={"resume.certification.fromYear.itil"}
      fromDate={"month.feb"}
      toDate={"presentDay"}
    />
    <CertificationHeading
      heading={"resume.certification.title.ipv6"}
      subHeading={"resume.certification.subTitle.ipv6"}
      year={"resume.certification.fromYear.ipv6"}
      fromDate={"month.nov"}
      toDate={"presentDay"}
    />
    </div>,


    /* WORK EXPERIENCE */
    <div className="resume-screen-container" key="work-experience">
      <div className="scrollable-div">
        <div className="experience-container">
          <ResumeHeading
            heading={"resume.section.experience.founder.title"}
            subHeading={"resume.section.experience.founder.subTitle"}
            fromDate={"Feb.2019"}
            toDate={"Present"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.founder.task1" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.founder.task2" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.founder.task3" />
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.founder.reference" />
            </span>
          </div>
        </div>

        <div className="experience-container">
          <ResumeHeading
            heading={"resume.section.experience.proserve.title"}
            subHeading={"resume.section.experience.proserve.subTitle"}
            fromDate={"Nov.2021"}
            toDate={"Sep.2023"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.proserve.task1" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.proserve.task2" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.proserve.task3" />
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.proserve.reference" />
            </span>
          </div>
        </div>


        <div className="experience-container">
          <ResumeHeading
            heading={"resume.section.experience.solutionArchitectParis.title"}
            subHeading={"resume.section.experience.solutionArchitectParis.subTitle"}
            fromDate={"Apr.2019"}
            toDate={"Nov.2021"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectParis.task1" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectParis.task2" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectParis.task3" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectParis.task4" />
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectParis.reference" />
            </span>
          </div>
        </div>
        <div className="experience-container">
          <ResumeHeading
            heading={"resume.section.experience.solutionArchitectLuxembourg.title"}
            subHeading={"resume.section.experience.solutionArchitectLuxembourg.subTitle"}
            fromDate={"Jan.2016"}
            toDate={"Apr.2019"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectLuxembourg.task1" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectLuxembourg.task2" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectLuxembourg.task3" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectLuxembourg.task4" />
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.solutionArchitectLuxembourg.reference" />
            </span>
          </div>
        </div>
        <div className="experience-container">
        <ResumeHeading
          heading={"resume.section.experience.emeaSystemEngineer.title"}
          subHeading={"resume.section.experience.emeaSystemEngineer.subTitle"}
          fromDate={"Jan.2015"}
          toDate={"Jan.2016"}
        />
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.emeaSystemEngineer.task1" />
               
              <ul>
                <li><FormattedMessage id="resume.section.experience.emeaSystemEngineer.task1.subTask1" /></li>
                <li><FormattedMessage id="resume.section.experience.emeaSystemEngineer.task1.subTask2" /></li>
                <li><FormattedMessage id="resume.section.experience.emeaSystemEngineer.task1.subTask3" /></li>
                <li><FormattedMessage id="resume.section.experience.emeaSystemEngineer.task1.subTask4" /></li>
                </ul>
            </span>
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.emeaSystemEngineer.task2" />
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.emeaSystemEngineer.reference" />
            </span>
          </div>
        </div>
        <div className="experience-container">
          <ResumeHeading
            heading={"resume.section.experience.operationalHostedServicesManager.title"}
            subHeading={"resume.section.experience.operationalHostedServicesManager.subTitle"}
            fromDate={"Jul.2012"}
            toDate={"Sep.2014"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.operationalHostedServicesManager.task1" />
            </span>
            <br />
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.operationalHostedServicesManager.task2" />
            </span>
            <br />
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.operationalHostedServicesManager.task3" />
              
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.operationalHostedServicesManager.reference" />
            </span>
          </div>
        </div>
        <div className="experience-container">
          <ResumeHeading
            heading={"resume.section.experience.networkArchitect.title"}
            subHeading={"resume.section.experience.networkArchitect.subTitle"}
            fromDate={"Dec.2008"}
            toDate={"Jun.2012"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.networkArchitect.task1" />
            </span>
            <br />
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.networkArchitect.task2" />
            </span>
            <br />
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.networkArchitect.task3" />
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.networkArchitect.reference" />
            </span>
          </div>
        </div>
        <div className="experience-container">
          <ResumeHeading
            heading={"resume.section.experience.projectDevlopmentCoordinator.title"}
            subHeading={"resume.section.experience.projectDevlopmentCoordinator.subTitle"}
            fromDate={"Mar.2008"}
            toDate={"Nov.2008"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.projectDevlopmentCoordinator.task1" />
            </span>
            <br />
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.projectDevlopmentCoordinator.task2" />
            </span>
            <br />
            <span className="resume-description-text">
              <FormattedMessage id="resume.section.experience.projectDevlopmentCoordinator.task3" />
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.projectDevlopmentCoordinator.reference" />
            </span>
          </div>
        </div>
        <div className="experience-container">
          <ResumeHeading
            heading={"resume.section.experience.securityConsultant.title"}
            subHeading={"resume.section.experience.securityConsultant.subTitle"}
            fromDate={"Oct.2007"}
            toDate={"Feb.2008"}
          />
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.securityConsultant.task1" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.securityConsultant.task2" />
            </span>
            <br />
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.securityConsultant.task3" />
            </span>
            <br />
          </div>
          <div className="experience-description">
            <span className="resume-description-text">
            <FormattedMessage id="resume.section.experience.securityConsultant.reference" />
            </span>
          </div>
        </div>
      </div>
    </div>,
    

    /* Interests */
    <div className="resume-screen-container" key="interests">
      <div className="scrollable-div">
        <CertificationHeading
          heading={"resume.interest.title.programming"}
          description={"resume.interest.description.programming"}
        />
        <CertificationHeading
          heading={"resume.interest.title.machineLearning"}
          description={"resume.interest.description.machineLearning"}
        />
        <CertificationHeading
          heading={"resume.interest.title.cybernetics"}
          description={"resume.interest.description.cybernetics"}
        />
        <CertificationHeading
          heading={"resume.interest.title.automatism"}
          description={"resume.interest.description.automatism"}
        />
        <CertificationHeading
          heading={"resume.interest.title.anthropology"}
          description={"resume.interest.description.anthropology"}
        />
        <CertificationHeading
          heading={"resume.interest.title.astronomy"}
          description={"resume.interest.description.astronomy"}
        />
        <CertificationHeading
          heading={"resume.interest.title.basketBall"}
          description={"resume.interest.description.basketBall"}
        />
        <CertificationHeading
          heading={"resume.interest.title.rugby"}
          description={"resume.interest.description.rugby"}
        />
        <CertificationHeading
          heading={"resume.interest.title.music"}
          description={"resume.interest.description.music"}
        />
      </div>
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
          alt="B"
        />
        <span className="bullet-label"><FormattedMessage id={bullet.label} /></span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"resume.title"} subHeading={"resume.subTitle"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
