import "./App.css";
import PortfolioContainer from "./PortfolioContainer/PortfolioContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModalProvider } from "./context/ModalContext";
import ScrollToTopButton from "./PortfolioContainer/ScrollButton/ScrollButton";
import { DataProvider, SelectedIndexProvider } from './context/DataContext';
import CookieBanner from "./PortfolioContainer/CookieBanner/CookieBanner";
import React from "react";

function App() {
  const initializeGA = () => {
    const gaCookieConsent = localStorage.getItem('HECCookieConsent');
    if (gaCookieConsent === 'true') {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
    
      gtag('js', new Date());
      gtag('config', 'G-JNK47GJBJP');
      // Load Google Analytics script
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-JNK47GJBJP`;
      script.onload = initializeGA;
      document.head.appendChild(script);
    }
  };

  const handleCookieAccept = () => {
    localStorage.setItem('HECCookieConsent', 'true');
    setTimeout(() => {
      initializeGA();
    }, 100);
  };

  return (
    <SelectedIndexProvider>
    <DataProvider>
    <ModalProvider>
    <div className="App">
      <ToastContainer />
        <PortfolioContainer />
      <ScrollToTopButton />
      <CookieBanner onAccept={handleCookieAccept}></CookieBanner>
    </div>
    </ModalProvider>
    </DataProvider>
    </SelectedIndexProvider>
  );
}

export default App;
