import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import {FormattedMessage} from 'react-intl';

const Modal = ({ children, onClose }) => {
  const modalRoot = document.getElementById('modal-root');
  const modalContainer = document.createElement('div');

  useEffect(() => {
    modalRoot.appendChild(modalContainer);

    return () => {
      modalRoot.removeChild(modalContainer);
    };
  }, [modalContainer, modalRoot]);

  const handleCloseModal = () => {
    onClose();
  };

  return createPortal(
    <div className="modal">
      {children}
      <button
        className="modal-btn btn primary-btn"
        onClick={handleCloseModal}
      >
        {" "}
        <FormattedMessage id="button.close" />{" "}
      </button>
    </div>,
    modalContainer
  );
};

export default Modal;