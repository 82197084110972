import React, { useState } from "react";
import "./Footer.css";
import {FormattedMessage} from 'react-intl';
import Legal from "../Legals/legals";
//import { useModal } from "../../context/ModalContext";
import Modal from '../modals/Modal';

export default function Footer() {

  const [newDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div className="footer">
      <p>2019 - { newDate.getFullYear() } © Head End Consulting </p>
      <p><button className="btn primary-btn" onClick={handleOpenModal}><FormattedMessage id="link.legals" /></button></p>
      <p>SIRET:  978 518 348 000 13 - APE: 6202A - TVA: FR18 978 518 348</p>
      </div>
      
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <Legal />
        </Modal>
      )}
    </div>
  );
}
