import React from 'react';
import CookieConsent from 'react-cookie-consent';
import {FormattedMessage} from 'react-intl';

const CookieBanner = ({ onAccept }) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText={<FormattedMessage id="acceptButton" />}
      cookieName="gaCookieConsent"
      style={{ background: '#222', color: '#fff' }}
      buttonStyle={{ background: '#6fa1d2', color: '#fff', fontSize: '14px' }}
      expires={365}
      onAccept={onAccept}
      enableDeclineButton
      declineButtonText={<FormattedMessage id="refuseButton" />}
    >
      <FormattedMessage id="cookieBanner.content" />
    </CookieConsent>
  );
};

export default CookieBanner;