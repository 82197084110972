import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Accreditations.css";
import PropTypes from "prop-types";

export default function Accreditations(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const ArticleEmbed = ({ embedPic, embedTitle, embedResume, articleUrl }) => {
  
    return (
      <a href={`${articleUrl}`} rel="noreferrer" target="_blank">
      <div className="col-lg-12">
        <div className="testi-item">
          <div className="accredi-comment">
            <img src={embedPic} loading="lazy" alt="Embedded Pic" />
            <h5>{embedTitle}</h5>
            <br />
            <p>
              <i className="fa fa-quote-left" />
              {embedResume}
              <i className="fa fa-quote-right" />
            </p>
          </div>
        </div>
      </div>
      </a>
    );
  };

  ArticleEmbed.propTypes = {
    embedPic: PropTypes.string.isRequired, 
    embedTitle: PropTypes.string.isRequired, 
    embedResume: PropTypes.string.isRequired,
    articleUrl: PropTypes.string.isRequired
  };

  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateIn: "bounceInRight",
    animateOut: "bounceOutRight",
    dots: true,
    autoplay: true,
    smartSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };

  return (
    <div className="creds-section fade-in" id={props.id || ""}>
      <ScreenHeading
        title={"creds.title"}
        subHeading={"creds.subTitle"}
      />
      <section className="">
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-carousel"
              id="testimonial-carousel"
              {...options}
            >

              <ArticleEmbed
              embedPic="https://images.credly.com/size/680x680/images/a253b994-caa6-4dd1-bf0e-434dd012b1f6/image.png "
              embedTitle="AWS Partner: Accreditation (Technical)"
              embedResume="Earners of this badge are AWS Partners who have developed fundamental, technical knowledge of AWS cloud computing, global infrastructure, services, solutions, migration and security."
              articleUrl="https://www.credly.com/badges/70cb1f5a-8842-426a-a50b-8e599dbb9491/public_url"/>

              <ArticleEmbed 
              embedPic="https://images.credly.com/size/340x340/images/0c6f66be-4cd6-4d98-b132-a9a87dc6ecbe/image.png"
              embedTitle="AWS Knowledge: Media & Entertainment: Direct-to-Consumer and Broadcast Foundations"
              embedResume="Earners of this badge have the foundational skills and knowledge to build and operate AWS Direct-to-Consumer or Broadcast workflows using AWS best practices."
              articleUrl="https://www.credly.com/badges/6a96ae13-d14b-453a-8062-740d0b33be16/public_url"/>

              <ArticleEmbed
              embedPic="https://images.credly.com/size/340x340/images/2d84e428-9078-49b6-a804-13c15383d0de/image.png"
              embedTitle="AWS Certified Solutions Architect – Professional"
              embedResume="Earners of this certification have an extensive understanding of designing technical strategies to accomplish specific business goals. They demonstrated the ability to balance best practices and trade-offs based on business context. Badge owners are able to design solutions across multiple platforms and providers."
              articleUrl="https://www.credly.com/badges/8a0e30d1-419f-414d-9cab-2115fe1e355e/public_url"/>

              <ArticleEmbed
              embedPic="https://images.credly.com/size/340x340/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png"
              embedTitle="AWS Certified Cloud Practitioner"
              embedResume="Earners of this certification have a fundamental understanding of IT services and their uses in the AWS Cloud. They demonstrated cloud fluency and foundational AWS knowledge. Badge owners are able to identify essential AWS services necessary to set up AWS-focused projects."
              articleUrl="https://www.credly.com/badges/1004eb52-70ed-4472-a9ba-a0c53c8d3974/public_url"/>

              <ArticleEmbed
              embedPic="https://images.credly.com/size/340x340/images/7b2c708c-a3e1-4c7f-985c-b6b62a5b1db8/image.png"
              embedTitle="AWS Partner: Accreditation (Business)"
              embedResume="This course is retired, however the owners of this badge demonstrated a foundational knowledge of key AWS products and services and understand effective client engagement strategies. AWS Partner learners should take the updated AWS Partner: Sales Accreditation (Business) course and assessment to earn the associated badge."
              articleUrl="https://www.credly.com/badges/789a3caa-f1d9-4f3e-a502-bfa40b5c956d/public_url"/>

              <ArticleEmbed
              embedPic="https://images.credly.com/size/340x340/images/0e284c3f-5164-4b21-8660-0d84737941bc/image.png"
              embedTitle="AWS Certified Solutions Architect – Associate"
              embedResume="Earners of this certification have a comprehensive understanding of AWS services and technologies. They demonstrated the ability to build secure and robust solutions using architectural design principles based on customer requirements. Badge owners are able to strategically design well-architected distributed systems that are scalable, resilient, efficient, and fault-tolerant."
              articleUrl="https://www.credly.com/badges/71a48cf1-bdfe-429c-8775-be988cd8b030/public_url"/>

            </OwlCarousel>
          </div>
        </div>
      </section>
    </div>
    
  );
}
