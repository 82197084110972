import React from "react";
import Typical from "react-typical";
import ScrollService from "../../../utilities/ScrollService";
import "./Profile.css";
import {FormattedMessage, useIntl} from 'react-intl';

export default function Profile() {
  const intl = useIntl();
  const roles = [
    intl.formatMessage({ id: 'typical.step1' }),
    1000,
    intl.formatMessage({ id: 'typical.step2' }),
    1000,
    intl.formatMessage({ id: 'typical.step3' }),
    1000,
    intl.formatMessage({ id: 'typical.step4' }),
    1000,
    intl.formatMessage({ id: 'typical.step5' }),
    1000,
    intl.formatMessage({ id: 'typical.step6' }),
    1000,
  ]
  
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              <a href="https://www.facebook.com/HeadEndConsulting" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-square-facebook"></i>
              </a>
              <a href="https://twitter.com/HeadEndConsult" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-square-twitter"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCOdM-YN9bSyanizNGYSX5iQ" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-square-youtube"></i>
              </a>
              <a href="https://fr.linkedin.com/company/headendconsulting" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://github.com/ethevee?tab=repositories" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-square-github"></i>
              </a>
              <a href="https://iq.aws/e/headendconsulting" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-aws"></i>
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              <FormattedMessage id="profile.detail.name1" />{" "}
              <span className="highlighted-text"><FormattedMessage id="myName" /></span>
              <br></br>
              <FormattedMessage id="profile.detail.name2" />
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h1>
                <Typical
                  loop={Infinity}
                  steps={roles}
                />
              </h1>
            </span>
            <span className="profile-role-tagline">
            <FormattedMessage id="profile.tagline1" />
              <br></br>
              <span className="highlighted-text">
                <FormattedMessage id="profile.tagline2" />
              </span>{" "}
              <FormattedMessage id="profile.tagline3" />
            </span>
          </div>

          <div className="profile-options">
            <button className="btn primary-btn"
            onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            ><FormattedMessage id="button.hireMe" /></button>
            <a href="etheve_emmanuel.pdf" download="Etheve Emmanuel.pdf">
              <button className="btn highlighted-btn"><FormattedMessage id="button.getResume" /></button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
