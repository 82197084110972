import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import News1 from "../../assets/News/news1.webp";
import News2 from "../../assets/News/news2.webp";
import News3 from "../../assets/News/news3.webp";
import News4 from "../../assets/News/news4.webp";
import "./News.css";
import PropTypes from "prop-types";
import VideoModal from '../modals/VideoModal';
import { useState } from 'react';
import Modal from '../modals/Modal';

export default function Testimonial(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  
  const handleOpenModal = (embedId) => {
    setCurrentVideoId(embedId);
    setIsModalOpen(true);
    console.log(isModalOpen);
  }

  const handleCloseModal = () => {
    setCurrentVideoId(null);
    setIsModalOpen(false);
    console.log('Modal is open:', isModalOpen);
  };

  const YoutubeEmbed = ({ embedTitle, embedId }) => (
    <div className="col-lg-12">
      <div className="testi-item">
        <div className="testi-comment">
          <div className="video-responsive">
          <img width='100%' height='300px' src={`https://img.youtube.com/vi/${embedId}/0.jpg`} alt="thumbnail"></img>
            <button className="player-btn" onClick={() => handleOpenModal(embedId)}><i className="fa-brands fa-youtube fa-xl" /></button>
          </div>
        </div>
      </div>
    </div>
  );

  YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
  };

  const ArticleEmbed = ({ embedPic, embedTitle, embedResume, articleUrl }) => {
  
    return (
      <a href={`${articleUrl}`} rel="noreferrer" target="_blank">
      <div className="col-lg-12">
        <div className="testi-item">
          <div className="testi-comment">
            <img src={embedPic} loading="lazy" alt="Embedded Pic" />
            <h5>{embedTitle}</h5>
            <br />
            <p>
              <i className="fa fa-quote-left" />
              {embedResume}
              <i className="fa fa-quote-right" />
            </p>
          </div>
        </div>
      </div>
      </a>
    );
  };

  ArticleEmbed.propTypes = {
    embedPic: PropTypes.string.isRequired, 
    embedTitle: PropTypes.string.isRequired, 
    embedResume: PropTypes.string.isRequired,
    articleUrl: PropTypes.string.isRequired
  };

  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateIn: "bounceInRight",
    animateOut: "bounceOutRight",
    dots: true,
    autoplay: true,
    smartSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };

  return (
    <div className="news-section fade-in" id={props.id || ""}>
      <ScreenHeading
        title={"news.title"}
        subHeading={"news.subTitle"}
      />
      <section className="">
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-carousel"
              id="testimonial-carousel"
              {...options}
            >
              
              <YoutubeEmbed embedTitle="Comment France Télévision utilise une architecture publicitaire basée sur le cloud AWS" embedId="84eaXZULXsk" />    

              <ArticleEmbed 
              embedPic={News1} 
              embedTitle="Channel deployment automation for AWS Elemental MediaLive with AWS CDK | Amazon Web Services" 
              embedResume="This is the first post in a series that explores ways in which you can automate your media infrastructure and streamline your operation processes. Doing so will help increase the repeatability of your workflows and reduce potential failures, as well as provide you with agility in the management of your media infrastructure."
              articleUrl="https://aws.amazon.com/fr/blogs/media/channel-deployment-automation-for-aws-elemental-medialive-with-aws-cdk"/>

              <ArticleEmbed 
              embedPic={News2}
              embedTitle="Improve streaming content security with SPEKE v2.0 and AWS Elemental MediaPackage" 
              embedResume="A quick history of CPIX and SPEKE Until the publishing of the DASH-IF’s Content Protection Information Exchange Format (CPIX) specification."
              articleUrl="https://aws.amazon.com/fr/blogs/media/improve-streaming-content-security-speke-v2-aws-elemental-mediapackage/"/>


              <ArticleEmbed 
              embedPic={News3} 
              embedTitle="How to get to market fast with Automated ABR encoding | Amazon Web Services" 
              embedResume="Automated ABR is an AWS Elemental MediaConvert feature that is ideal for customers who are launching an application and require the encoding of video files, but are not video experts."
              articleUrl="https://aws.amazon.com/fr/blogs/media/how-to-get-to-market-fast-with-automated-abr-encoding/"/>

              <ArticleEmbed 
              embedPic={News4} 
              embedTitle="Live media workflows on AWS: To compress, or to not compress? | Amazon Web Services" 
              embedResume="Introduction In a cloud migration journey, there are many decisions that need to be made. One of the primary decisions for live media workflows is how to bring content from on-premises to the cloud."
              articleUrl="https://aws.amazon.com/fr/blogs/media/metfc-live-media-workflows-on-aws-to-compress-or-to-not-compress/"/>
            </OwlCarousel>
          </div>
        </div>
      </section>
      {isModalOpen && (
              <Modal onClose={handleCloseModal}>
                <VideoModal videoId={currentVideoId}/>
              </Modal>
            )}
    </div>
    
  );
}
