import React, { useEffect, useState } from "react";
import "./ScrollButton.css"; // Import your CSS for styling

const ScrollToTopButton = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 80   ); // Adjust the threshold as needed
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={`scroll-to-top ${isScrolled ? "visible" : ""}`}
      onClick={scrollToTop}
    >
      <i className="fa fa-arrow-up"></i>
    </button>
  );
};

export default ScrollToTopButton;