import React from "react";
import './modal.css'

const VideoModal = ({videoId}) => {
  return (
    <div className="size">
      <iframe width="640px" height="480px" src={`https://www.youtube.com/embed/${videoId}?autoplay=1&amp;showinfo=0`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      
    </div>
    
  );
};

export default VideoModal;   