import React, { useState, useEffect } from "react";
import {
  TOTAL_SCREENS,
  GET_SCREEN_INDEX,
} from "../../../utilities/commonUtils";
import ScrollService from "../../../utilities/ScrollService";
import "./Header.css";
import {FormattedMessage} from 'react-intl';
import { useSelectedIndex } from "../../../context/DataContext";

export default function Header() {
  const [selectedScreen, setSelectedScreen] = useState(0);
  const [showHeaderOptions, setShowHeaderOptions] = useState(false);
  const { selectedBulletIndex, updateSelectedIndex } = useSelectedIndex();

  const updateCurrentScreen = (currentScreen) => {
    if (!currentScreen || !currentScreen.screenInView) return;

    let screenIndex = GET_SCREEN_INDEX(currentScreen.screenInView);
    if (screenIndex < 0) return;
  };
  let currentScreenSubscription =
    ScrollService.currentScreenBroadcaster.subscribe(updateCurrentScreen);


  const handleDropdownItemClick = (index) => {
    updateSelectedIndex(index);
  };

  const getHeaderOptions = () => {
    return TOTAL_SCREENS.map((Screen, i) => (
      <div>
      {
        Screen.submenu ?
      <div
        key={Screen.screen_name}
        className={getHeaderOptionsClasses(i)}
        onClick={() => switchScreen(i, Screen)}
      >
        <div className="dropdown">
        <span>{Screen.screen_name}</span>
         <div className="dropdown-content">{getSubmenu(Screen.submenu)}</div> 
        
        </div>
      </div> : 
      <div
      key={Screen.screen_name}
      className={getHeaderOptionsClasses(i)}
      onClick={() => switchScreen(i, Screen)}
      >
        <span>{Screen.screen_name}</span>
      </div> 
      } 
      </div>
    ));
  };

  const getSubmenu = (screen) => {
    console.log(screen);
    return screen.map((Screen, i) => (
        <a onClick={() => handleDropdownItemClick(i)} href="#" key={i} >{Screen.name}</a>
    ));
  }

  const getHeaderOptionsClasses = (index) => {
    let classes = "header-option ";
    if (index < TOTAL_SCREENS.length - 1) classes += "header-option-seperator ";

    if (selectedScreen === index) classes += "selected-header-option ";

    return classes;
  };

  const switchScreen = (index, screen) => {
    let screenComponent = document.getElementById(screen.screen_name);
    if (!screenComponent) return;

    screenComponent.scrollIntoView({ behavior: "smooth" });
    setSelectedScreen(index);
    setShowHeaderOptions(false);
  };

  useEffect(() => {
    return () => {
      currentScreenSubscription.unsubscribe();
    };
  }, [currentScreenSubscription]);

  return (
    <div
      className="header-container"
      onClick={() => setShowHeaderOptions(!showHeaderOptions)}
    >
      <div className="header-parent">
        <div
          className="header-hamburger"
          onClick={() => setShowHeaderOptions(!showHeaderOptions)}
        >
          <i className="fa-solid fa-bars header-hamburger-bars" style={{color: '#ffffff'}}></i>
        </div>
        <div className="logo-section">
          <img  
            className="logo"
            src={require("../../../assets/Home/logo.svg").default}
            alt="Logo"
          />
          <div className="header-logo">
          <span><FormattedMessage id="home.title1" /><br></br><FormattedMessage id="home.title2" /></span>
          </div>
        </div>
        <div
          className={
            showHeaderOptions
              ? "header-options show-hamburger-options"
              : "header-options"
          }
        >
          {getHeaderOptions()}
        </div>
      </div>
    </div>
  );
}
