import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Projects.css";
import shape from "../../../src/img/Testimonial/shape-bg.png";
import PropTypes from "prop-types";
import VideoModal from '../modals/VideoModal';
import { useState } from 'react';
import Modal from '../modals/Modal';

export default function Projects(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  
  const handleOpenModal = (embedId) => {
    setCurrentVideoId(embedId);
    setIsModalOpen(true);
    console.log(isModalOpen);
  }

  const handleCloseModal = () => {
    setCurrentVideoId(null);
    setIsModalOpen(false);
    console.log('Modal is open:', isModalOpen);
  };

  const YoutubeEmbed = ({ embedTitle, embedId }) => (
      <div className="testi-item">
        <div className="testi-comment">
          <div className="video-responsive" >
            <img className="img-size"  src={`https://img.youtube.com/vi/${embedId}/0.jpg`} alt="thumbnail"></img>
            <button className="player-btn" onClick={() => handleOpenModal(embedId)}><i className="fa-brands fa-youtube fa-xl" /></button>
          </div>
        </div>
    </div>
  );  
  

  YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
  };
  
  const ArticleEmbed = ({ embedPic, embedTitle, embedResume, articleUrl }) => (
    <div className="col-lg-12">
      <div className="testi-item">
        <div onClick={() => window.open(articleUrl, '_blank')} className="testi-comment">
          <img src={`${embedPic}`} loading="lazy" alt="embedTitle"></img>
          <h5>{embedTitle}</h5>
          <br></br>
          <p>
            <i className="fa fa-quote-left" />
            {embedResume}
            <i className="fa fa-quote-right" />
          </p>
        </div>
      </div>
    </div>
  );

  ArticleEmbed.propTypes = {
    embedPic: PropTypes.string.isRequired, 
    embedTitle: PropTypes.string.isRequired, 
    embedResume: PropTypes.string.isRequired,
    articleUrl: PropTypes.string.isRequired
  };

  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateIn: "bounceInRight",
    animateOut: "bounceOutRight",
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div className="fade-in" id={props.id || ""}>
      <ScreenHeading
        title={"projects.title"}
        subHeading={"projects.subTitle"}
      />
      <section className="testimonial-section">
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-carousel"
              id="testimonial-carousel"
              {...options}
            >
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <YoutubeEmbed embedTitle="vuitton mobile" embedId="KAdDW-sRuVw" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <YoutubeEmbed embedTitle="Jean-Michel Jarre - Oxygene Pt. 17 (Official 360° Video)" embedId="ahA8aYI4Xno" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                  <YoutubeEmbed embedTitle="AWS re:Invent 2018: Broadcasting Sporting Events: AWS Media Services When It Matters Most (CTD206)" embedId="Mj9-CQ1PRCk" />
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      <div className="footer-image">
        <img src={shape} loading="lazy" alt="" />
      </div>

      {isModalOpen && (
              <Modal onClose={handleCloseModal}>
                <VideoModal videoId={currentVideoId}/>
              </Modal>
            )}
    </div>
  );
}
