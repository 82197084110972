import React from "react";
//import { useModal } from "../../context/ModalContext";
import "./Legals.css";
import {FormattedMessage} from 'react-intl';
const Legal = () => {
  return (
    <div>
        <div className="content">
            <h1><FormattedMessage id="legals.title" /></h1>
            <h2><FormattedMessage id="legals.company" /></h2>
            <p><FormattedMessage id="legals.owner" /></p>
            <p>Headend Consulting SAS</p>
            <p>17 RUE DES FLEURIOTTES 78410 AUBERGENVILLE</p>
            <p>SIRET: 978 518 348 000 13</p>
            <p>TVA: FR18 978 518 348</p>
            <h2><FormattedMessage id="legals.housing" /></h2>
            <p>Amazon Web Services Sucursal Francaise SARL</p>
            <p>32 place des corrolles<br></br>92400 COURBEVOIE<br></br>FRANCE</p> 
            <h2><FormattedMessage id="legals.publication_director" /></h2>
            <p>Mr. Emmanuel ETHEVE</p>
            <p><FormattedMessage id="legals.contact" /></p>
        </div>
    </div>
  );
};

export default Legal;
