import React from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";
import {FormattedMessage} from 'react-intl';

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SERVICE_ARRAY_LENGTH = 15;
  const renderHighlight = (length) => {
    return Array.from({ length }, (_, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span><FormattedMessage id={`about.service${i+1}`} /></span>
      </div>
    ));
  };

  return (
    <div
      className="about-me-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="about-me-parent">
        <ScreenHeading title={"about.title"} subHeading={"about.subtitle"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              <p><FormattedMessage id="about.history" /></p>
              <p><FormattedMessage id="about.experience" /></p>
              <p><FormattedMessage id="about.services" /></p>
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span><FormattedMessage id="about.services.heading"/></span>
              </div>
              {renderHighlight(SERVICE_ARRAY_LENGTH)}
              
            </div>
            <span className="about-me-description">
              <p><FormattedMessage id="about.contact" /></p>
              <p><FormattedMessage id="about.greeting" /></p>
            </span>
            
            <div className="about-me-options">
              <button
                className="btn primary-btn"
                onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
              >
                {" "}
                <FormattedMessage id="button.hireMe" />{" "}
              </button>
              <a href="etheve_emmanuel.pdf" download="Etheve Emmanuel.pdf">
                <button className="btn highlighted-btn"><FormattedMessage id="button.getResume" /></button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
