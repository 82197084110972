import { createContext, useContext, useState } from 'react';
import React from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [sharedData, setSharedData] = useState(null);

  const updateData = (newData) => {
    setSharedData(newData);
  };

  return (
    <DataContext.Provider value={{ sharedData, updateData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
    const context = useContext(DataContext);
  
    if (!context) {
      throw new Error('useData must be used within a DataProvider');
    }
  
    return context;
  };


const SelectedIndexContext = createContext();

export const SelectedIndexProvider = ({ children }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const updateSelectedIndex = (index) => {
    setSelectedIndex(index);
  };

  const contextValue = {
    selectedIndex,
    updateSelectedIndex,
  };


  React.useEffect(() => {
    setSelectedIndex(0);
  }, []);

  return (
    <SelectedIndexContext.Provider value={contextValue}>
      {children}
    </SelectedIndexContext.Provider>
  );
};

export const useSelectedIndex = () => {
  console.log("in the context = " + useContext(SelectedIndexContext).selectedIndex);
  return useContext(SelectedIndexContext);
};

export const useSelectedIndexContext = () => useContext(SelectedIndexContext);
